import { Suspense, useContext, createContext, useMemo, useState, useCallback } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { onlyText } from '../../../utils/ChildrenUtils';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import XIcon from '../icon/XIcon';
import { Heading, HeadingSize } from '../text/Heading';
import Tooltip from '../Tooltip';
import { useModal } from '../../../contexts/ModalContext';
import PageLoader from '../page-loader/PageLoader';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useLayoutMargins } from '../../../global-state/Layout';

const Icon: FCWithChildren = (props) => {
  return (
    <div className="flex justify-center px-3 py-4" data-cy="modal-icon">
      {props.children}
    </div>
  );
};

type HeaderProps = {
  defaultStyle?: boolean;
  align?: 'left' | 'center';
  underline?: boolean;
};

const Header: FCWithChildren<HeaderProps> = (props) => {
  const { defaultStyle = true, children, align, underline } = props;
  const { modalId } = useInternalModal();

  return (
    <Tooltip text={onlyText(children)} truncatedTextMode>
      {(tooltip) => {
        return (
          <div
            {...tooltip}
            // Tooltip above contains a tabIndex={0}, we override it here
            tabIndex={-1}
            className={`pr-4 ${underline ? 'border-gray-5 border-b' : ''}`}
          >
            {defaultStyle ? (
              <Heading
                id={`modal-title-${modalId}`}
                data-cy="modal-header"
                size={HeadingSize.CUSTOM}
                className={`${align !== 'left' ? 'text-center' : ''} text-dpm-32 pl block px-6 py-4 pb-2 font-normal empty:hidden`}
              >
                {children}
              </Heading>
            ) : (
              <>{children}</>
            )}
          </div>
        );
      }}
    </Tooltip>
  );
};

const SubHeader: FCWithChildren = (props) => {
  return <div className="text-dpm-20 px-6 pb-4 pt-0 text-center">{props.children}</div>;
};

type BodyProps = {
  padding?: string;
};

const Body: FCWithChildren<BodyProps> = (props) => {
  const { children, padding = 'px-6 py-4' } = props;
  const { modalId, bodyMaxHeightCss } = useInternalModal();

  return (
    <div
      id={`modal-description-${modalId}`}
      data-cy="modal-body"
      className={`${padding} text-color-3 min-h-15 h-full ${bodyMaxHeightCss} overflow-y-auto`}
    >
      <Suspense
        fallback={
          <div className="p-8">
            <PageLoader loading isSuspense loaderSize={16} />
          </div>
        }
      >
        {children}
      </Suspense>
    </div>
  );
};
type FooterProps = {
  padding?: string;
  justification?: string;
  topline?: boolean;
};

const Footer: FCWithChildren<FooterProps> = (props) => {
  const { children, padding = 'px-6 py-4', justification = 'justify-center', topline = true } = props;
  return (
    <div data-cy="modal-footer" className={`${padding} flex items-center ${justification} gap-4 ${topline ? 'border-gray-5 border-t' : ''}`}>
      {children}
    </div>
  );
};

type ModalType = FCWithChildren<{ uncloseable?: boolean }> & {
  Icon: typeof Icon;
  Header: typeof Header;
  SubHeader: typeof SubHeader;
  Body: typeof Body;
  Footer: typeof Footer;
};

const InternalModalContext = createContext({ priority: 0, modalId: '', bodyMaxHeightCss: 'max-h-[calc(100vh-16rem)]' });
const useInternalModal = () => useContext(InternalModalContext);

export const Modal: ModalType = (props) => {
  const { children, uncloseable } = props;
  const {
    open,
    modalWidth = 'w-3/5',
    modalMaxWidth = 'max-w-[calc(100vw-4rem)]',
    showCloseButton = true,
    onClose,
    closeOnBackdropClick,
    className,
    priorityOverride,
    position = 'center',
    maxHeight = 'calc(100vh - 4rem)',
    height,
  } = useModal();
  const { priority: parentPriority } = useInternalModal();
  const { t } = useTranslation('common');
  const leftLayoutMargin = useLayoutMargins((x) => x.value);

  const modalId = useMemo(() => uuid(), []);
  const [isExiting, setIsExiting] = useState(false);

  const { refs, context } = useFloating({
    open: open,
    onOpenChange: (o) => {
      !o && onClose && onClose();
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    escapeKey: true,
    enabled: closeOnBackdropClick,
    outsidePressEvent: 'mousedown',
    outsidePress: (event) => {
      return (event.target as HTMLElement)?.dataset['backdrop'] === modalId;
    },
  });

  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  const animationClass = useMemo(() => {
    return isExiting
      ? position === 'end'
        ? 'animate-slide-out-to-right'
        : 'animate-fade-out-scale'
      : position === 'end'
        ? 'animate-slide-in-from-right'
        : 'animate-fade-in-scale';
  }, [isExiting, position]);

  const containerClass = useMemo(() => {
    return `${className} relative min-w-96 overflow-y-auto rounded-[5px] bg-white shadow-xl ${leftLayoutMargin} ${modalWidth} ${modalMaxWidth} ${animationClass}`;
  }, [className, leftLayoutMargin, modalWidth, modalMaxWidth, animationClass]);

  const handleClose = useCallback(() => {
    setIsExiting(true);
  }, []);

  const handleAnimationEnd = useCallback(() => {
    if (isExiting) {
      onClose && onClose();
      setIsExiting(false);
    }
  }, [isExiting, onClose]);

  return (
    open && (
      <FloatingPortal id="portal-root">
        <FloatingOverlay
          lockScroll
          style={{
            overflow: 'hidden',
            background: 'rgba(var(--color-gray-1), 0.5)',
            backdropFilter: 'blur(2px)',
            display: 'grid',
            placeItems: position,
            zIndex: 500 + (priorityOverride ?? parentPriority) + 1,
          }}
          data-backdrop={modalId}
        >
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              ref={refs.setFloating}
              {...getFloatingProps({ style: { maxHeight: maxHeight, height: height, zIndex: 500 + (priorityOverride ?? parentPriority) + 2 } })}
              className={containerClass}
              data-cy="modal-container"
              aria-labelledby={`modal-title-${modalId}`}
              aria-describedby={`modal-description-${modalId}`}
              {...dataAttributeProps(props)}
              onClick={(e) => e.stopPropagation()}
              onAnimationEnd={handleAnimationEnd}
            >
              {onClose && showCloseButton && !uncloseable && (
                <XIcon
                  aria-label={t('aria-label.close')}
                  data-cy="modal-close"
                  className="absolute right-0 top-0 mr-[20px] mt-[20px] h-5 w-5"
                  onClick={handleClose}
                />
              )}
              <InternalModalContext.Provider
                value={{
                  priority: (priorityOverride ?? parentPriority) + 2,
                  modalId,
                  bodyMaxHeightCss: position === 'end' ? 'max-h-[calc(100vh-11.2rem)]' : 'max-h-[calc(100vh-16rem)]',
                }}
              >
                <Suspense fallback={null}>{children}</Suspense>
              </InternalModalContext.Provider>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    )
  );
};

Modal.Icon = Icon;
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.SubHeader = SubHeader;
